(function () {
    'use strict';

    angular.module('modules.page-performance').component('pagePerformanceDialogsAuditDetails', {
        templateUrl: 'app/modules/page-performance/dialogs/audit-details/audit-details.html',
        controller: PagePerformanceDialogsAuditDetailsController,
        controllerAs: 'vm',
        bindings: {
            audit: '<',
        },
    });

    PagePerformanceDialogsAuditDetailsController.$inject = ['ng2PagePerformanceAuditService', 'exporter'];
    /* @ngInject */
    function PagePerformanceDialogsAuditDetailsController (ng2PagePerformanceAuditService, exporter) {
        var vm = this;
        vm.$onInit = activate;
        vm.scoreDisplayModeIsNumeric = scoreDisplayModeIsNumeric;
        vm.scoreDisplayModeIsBinary = scoreDisplayModeIsBinary;
        vm.scoreDisplayModeIsManual = scoreDisplayModeIsManual;
        vm.scoreDisplayModeIsInformative = scoreDisplayModeIsInformative;
        vm.scoreDisplayModeIsNotApplicable = scoreDisplayModeIsNotApplicable;
        vm.exportCsv = exportCsv;

        function activate () {}

        function scoreDisplayModeIsNumeric () {
            return ng2PagePerformanceAuditService.scoreDisplayModeIsNumeric(vm.audit.scoreDisplayMode);
        }

        function scoreDisplayModeIsBinary () {
            return ng2PagePerformanceAuditService.scoreDisplayModeIsBinary(vm.audit.scoreDisplayMode);
        }

        function scoreDisplayModeIsManual () {
            return ng2PagePerformanceAuditService.scoreDisplayModeIsManual(vm.audit.scoreDisplayMode);
        }

        function scoreDisplayModeIsInformative () {
            return ng2PagePerformanceAuditService.scoreDisplayModeIsInformative(vm.audit.scoreDisplayMode);
        }

        function scoreDisplayModeIsNotApplicable () {
            return ng2PagePerformanceAuditService.scoreDisplayModeIsNotApplicable(vm.audit.scoreDisplayMode);
        }

        function exportCsv () {
            if (vm.audit && vm.audit.details && vm.audit.details.items) {
                return exporter.parsePerformanceAuditsDetails(vm.audit.details.items);
            } else if (vm.audit && vm.audit.details && vm.audit.details.chains) {
                var result = [];
                Object.keys(vm.audit.details.chains).forEach(function (key) {
                    result = result.concat(
                        exporter.parsePerformanceAuditsDetails(
                            Object.keys(vm.audit.details.chains[key].children).map(function (childKey) {
                                return vm.audit.details.chains[key].children[childKey];
                            }),
                        ),
                    );
                });
                return result;
            } else {
                return null;
            }
        }
        // PROTECTED
    }
})();
