(function () {
    'use strict';

    angular.module('modules.light.forms').component('formLiteUser', {
        templateUrl: 'app/modules/light/forms/user/user.html',
        controller: LiteUserController,
        controllerAs: 'vm',
        bindings: {
            user: '<?',
            monDialogId: '@?',
            setForm: '&?',
        },
    });

    LiteUserController.$inject = [
        'ng2UserService',
        'UserRepo',
        'ng2DomainRepoService',
        '$q',
        'ng2SessionService',
        'monDialog',
        '$timeout',
        'ng2CoreLoginService',
        '$deepmerge',
    ];
    /* @ngInject */
    function LiteUserController (ng2UserService, UserRepo, ng2DomainRepoService, $q, ng2SessionService, monDialog, $timeout, ng2CoreLoginService, $deepmerge) {
        var vm = this;
        vm.submit = submit;
        vm.getDomains = getDomains;
        vm.setDomainVisibility = setDomainVisibility;
        vm.close = close;

        vm.$onInit = activate;

        function activate () {
            vm.domains = [];
            vm.user = vm.user || {};
            vm.user.customer_admin = vm.user.customer_admin || false;
            vm.user.send_welcome_email = vm.user.send_welcome_email || true;
            vm.progress = getDomains().then(function (values) {
                vm.domains = values;
            }, angular.noop);
            $timeout(function () {
                if (vm.setForm) {
                    vm.setForm({ id: vm.monDialogId, form: vm.userLiteForm });
                }
            });
        }

        function getDomains () {
            return ng2DomainRepoService.getAll({ page_size: 50, mini: true });
        }

        async function submit () {
            const user = $deepmerge({}, vm.user);
            user.domain_users = setDomainVisibility('domain_users');
            user.domain_group_members = setDomainVisibility('domain_group_members');
            if (Array.isArray(user.labels)) {
                user.labels = user.labels.map(function (label) {
                    return label.id;
                });
            }
            vm.onSave = true;
            const deferred = $q.defer();
            if (user.id === undefined || user.id === null) {
                deferred.resolve(await ng2UserService.create(user));
            } else {
                deferred.resolve(await ng2UserService.update(user));
            }

            deferred.promise.then(
                (data) => {
                    // TODO: Code underneath should be changed with: https://zube.io/monsido/monsido/c/23283
                    if (vm.userLiteForm) {
                        vm.userLiteForm.$setPristine();
                    }
                    close(data);
                    if (ng2SessionService.me && data.id === ng2SessionService.me.id) {
                        ng2CoreLoginService.loadUser();
                    }
                },
                () => {
                    vm.onSave = false;
                },
            );
        }

        function setDomainVisibility (domainParam) {
            if (!vm.user[domainParam]) {
                vm.user[domainParam] = [];
            }
            return vm.user[domainParam].map(function (domain) {
                domain.visible = vm.user.customer_admin || domain.visible;
                return domain;
            });
        }

        function close (user) {
            monDialog.close(vm.monDialogId, user);
        }
    }
})();
