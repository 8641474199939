import { Injectable } from '@angular/core';
import { ProfileRepoRequestParams, ProfilesRepoService } from '../../api/page-performance/profiles-repo/profiles-repo.service';
import { HttpContextToken } from '@angular/common/http';
import { CollectionInterface } from '@monsido/angular-shared-components/dist/angular-shared-components/lib/interfaces/collection.interface';
import { PagePerformanceProfile } from '@client/app/models/page-performance/profile.model';

@Injectable({
    providedIn: 'root',
})
export class PagePerformanceProfileService {

    constructor (
        private profilesRepoService: ProfilesRepoService,
    ) { }

    getAll (params: ProfileRepoRequestParams, contextToken: HttpContextToken<boolean>): Promise<CollectionInterface<PagePerformanceProfile>> {
        return this.profilesRepoService.getAll(params, contextToken);
    }

    create (profile: PagePerformanceProfile): Promise<PagePerformanceProfile> {
        return this.profilesRepoService.create(profile);
    }

    update (profile: PagePerformanceProfile): Promise<PagePerformanceProfile> {
        return this.profilesRepoService.update(profile);
    }

    archive (profile: PagePerformanceProfile): Promise<PagePerformanceProfile> {
        return this.profilesRepoService.archiveProfile(profile);
    }
}
