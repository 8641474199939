import demoData from './../../../../demo/cookie-overview.json';

(function () {
    'use strict';
    angular.module('modules.cookie_banner.components').service('CookieOverviewService', CookieOverviewService);

    CookieOverviewService.$inject = [
        'ng2CookiesRepoService',
        '$q',
        'ng2DemoHasAccessService',
        'COOKIE_CATEGORIES',
        'COOKIE_FIELDS',
        'ng2MonModalService',
        'gettextCatalog',
        '$state',
        'ng2ToastService',
        'ng2CookieModelService',
        'ng2MonUIRouterTransitionService',
    ];
    /* @ngInject*/
    function CookieOverviewService (
        ng2CookiesRepoService,
        $q,
        ng2DemoHasAccessService,
        COOKIE_CATEGORIES,
        COOKIE_FIELDS,
        ng2MonModalService,
        gettextCatalog,
        $state,
        toastService,
        ng2CookieModelService,
        ng2MonUIRouterTransitionService,
    ) {
        return {
            getCookies: getCookies,
            getCookiesFromCentralDb: getCookiesFromCentralDb,
            createMultipleCookies: createMultipleCookies,
            updateMultipleCookies: updateMultipleCookies,
            removeMultipleCookies: removeMultipleCookies,
            hasDemoAccess: hasDemoAccess,
            confirmSavePopup: confirmSavePopup,
            successToast: successToast,
            errorToast: errorToast,
        };

        // ///////////////

        function getCookies (domainId) {
            if (hasDemoAccess()) {
                return $q.resolve(getDemoCookies());
            } else {
                return ng2CookiesRepoService.getAll({ domain_id: domainId });
            }
        }

        async function getCookiesFromCentralDb () {
            if (hasDemoAccess()) {
                return $q.resolve(getDemoCookiesFromCentralDb());
            } else {
                return await ng2CookiesRepoService.getCookiesFromCentralDb();
            }
        }

        function createMultipleCookies (cookies, domainId) {
            if (hasDemoAccess()) {
                return $q.resolve(true);
            }
            var promises = [];
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var cookieOptions = {};
                cookieOptions[COOKIE_FIELDS.name] = cookie.name;
                cookieOptions[COOKIE_FIELDS.domain] = cookie.domain;
                cookieOptions[COOKIE_FIELDS.platform] = cookie.platform;
                cookieOptions[COOKIE_FIELDS.source] = cookie.source;
                cookieOptions[COOKIE_FIELDS.type] = cookie.type;
                cookieOptions[COOKIE_FIELDS.descriptions] = cookie.descriptions;
                cookieOptions[COOKIE_FIELDS.expirations] = cookie.expirations;

                var promise = ng2CookiesRepoService.create(domainId, ng2CookieModelService.createCookie(cookieOptions));
                promises.push(promise);
            }

            return $q.all(promises);
        }

        function updateMultipleCookies (cookies, domainId) {
            if (hasDemoAccess()) {
                return $q.resolve(true);
            }
            var promises = [];
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var cookieOptions = {};
                cookieOptions[COOKIE_FIELDS.id] = cookie.id;
                cookieOptions[COOKIE_FIELDS.name] = cookie.name;
                cookieOptions[COOKIE_FIELDS.domain] = cookie.domain;
                cookieOptions[COOKIE_FIELDS.platform] = cookie.platform;
                cookieOptions[COOKIE_FIELDS.source] = cookie.source;
                cookieOptions[COOKIE_FIELDS.type] = cookie.type;
                cookieOptions[COOKIE_FIELDS.descriptions] = cookie.descriptions;
                cookieOptions[COOKIE_FIELDS.expirations] = cookie.expirations;

                var promise = ng2CookiesRepoService.update(domainId, ng2CookieModelService.createCookie(cookieOptions), {});
                promises.push(promise);
            }

            return $q.all(promises).then(function (res) {
                return res;
            }, function (error) {
                return $q.reject(error);
            });
        }

        function removeMultipleCookies (cookies, domainId) {
            if (hasDemoAccess()) {
                return $q.resolve(true);
            }
            var promises = [];
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var promise = ng2CookiesRepoService.destroy(domainId, cookie.id);
                promises.push(promise);
            }

            return $q.all(promises);
        }

        function confirmSavePopup (params) {
            ng2MonModalService.customDialog({
                title: gettextCatalog.getString('Save existing cookies'),
                message: gettextCatalog.getString('Save your page before leaving to keep your progress and publish the completed cookies.'),
                buttons: [
                    {
                        label: gettextCatalog.getString('Leave without saving'),
                        className: 'btn btn-secondary',
                        callback: function () {
                            // remove listener before route to next state
                            params.options.listenerRef();
                            ng2MonUIRouterTransitionService.onTransitionFinished(
                                () => $state.go(params.transition.to().name, params.transition.params()),
                            );
                        },
                        value: 'leave',
                    },
                    {
                        label: gettextCatalog.getString('Save cookies'),
                        className: 'btn btn-primary',
                        callback: function () {
                            if (params.options.form.$valid) {
                                // performing form submit and continue transition
                                params.callback().then(function () {
                                    params.options.listenerRef();
                                    ng2MonUIRouterTransitionService.onTransitionFinished(
                                        () => $state.go(params.transition.to().name, params.transition.params()),
                                    );
                                });
                            } else {
                                toastService.warning(gettextCatalog.getString('Could not save invalid form'));
                            }
                        },
                        value: 'save',
                    },
                ],
            });
        }

        function hasDemoAccess () {
            return ng2DemoHasAccessService.isDemoConsentManager();
        }

        function getDemoCookies () {
            return demoData;
        }

        function successToast () {
            return toastService.success(gettextCatalog.getString('Cookie Overview saved'));
        }

        function errorToast (msg) {
            return toastService.error(gettextCatalog.getString(msg));
        }
    }
})();
