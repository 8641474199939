import { CustomerInterface, Settings, PlanTraits, Reseller, Branding } from '@monsido/ng2/modules/models/api/interfaces/customer.interface';

export class Customer implements CustomerInterface {
    country: string;
    id: number;
    name: string;
    address: string;
    zipcode: string;
    city: string;
    state: string;
    vat_number: string;
    phone_number: string;
    timezone: string;
    settings: Settings;
    plan_traits: PlanTraits;
    branding: Branding;
    plan_type: string;
    reseller: Reseller;
    html_pages_count: number;
    documents_count: number;
    vertical: string;

    constructor (attributes: CustomerInterface) {
        this.id = attributes.id;
        this.name = attributes.name;
        this.address = attributes.address;
        this.zipcode = attributes.zipcode;
        this.city = attributes.city;
        this.state = attributes.state;
        this.country = attributes.country || 'US';
        this.vat_number = attributes.vat_number;
        this.phone_number = attributes.phone_number;
        this.timezone = attributes.timezone;
        this.settings = {
            statistics: attributes.settings.statistics,
            accessibility: attributes.settings.accessibility,
            respect_do_not_track: attributes.settings.respect_do_not_track,
            legacy_compliance: attributes.settings.legacy_compliance,
            legacy_check_compliance: attributes.settings.legacy_check_compliance,
            legacy_seo_compliance: attributes.settings.legacy_seo_compliance,
        };
        this.branding = {
            menu_background_color: attributes.branding.menu_background_color,
        };
        this.plan_traits = {
            advanced_analytics: attributes.plan_traits.advanced_analytics,
            accessibility: attributes.plan_traits.accessibility,
            accessibility_summary: attributes.plan_traits.accessibility_summary,
            account_settings: attributes.plan_traits.account_settings,
            api_users: attributes.plan_traits.api_users,
            clarity_pdf_accessibility: attributes.plan_traits.clarity_pdf_accessibility,
            clarity_pdf_remediation: attributes.plan_traits.clarity_pdf_remediation,
            closed_caption: attributes.plan_traits.closed_caption,
            cookie_banner: attributes.plan_traits.cookie_banner,
            custom_crawl_callback: attributes.plan_traits.custom_crawl_callback,
            custom_scan_interval: attributes.plan_traits.custom_scan_interval,
            dashboard_important_notifications: attributes.plan_traits.dashboard_important_notifications,
            data_export: attributes.plan_traits.data_export,
            data_protection: attributes.plan_traits.data_protection,
            domain_group_statistics: attributes.plan_traits.domain_group_statistics,
            general_book_training: attributes.plan_traits.general_book_training,
            general_chat_support: attributes.plan_traits.general_chat_support,
            general_shortcuts: attributes.plan_traits.general_shortcuts,
            heartbeat: attributes.plan_traits.heartbeat,
            heatmap_click: attributes.plan_traits.heatmap_click,
            heatmap_movement: attributes.plan_traits.heatmap_movement,
            heatmap_scroll: attributes.plan_traits.heatmap_scroll,
            heatmaps_monthly_page_sessions: attributes.plan_traits.heatmaps_monthly_page_sessions,
            heatmaps_monthly_sessions: attributes.plan_traits.heatmaps_monthly_sessions,
            heatmaps_tracked_number_of_pages: attributes.plan_traits.heatmaps_tracked_number_of_pages || 10,
            heatmaps_traffic_percentage: attributes.plan_traits.heatmaps_traffic_percentage,
            history_center_all: attributes.plan_traits.history_center_all,
            image_metadata: attributes.plan_traits.image_metadata,
            inventory_all: attributes.plan_traits.inventory_all,
            max_domains: attributes.plan_traits.max_domains,
            max_scanned_pages: attributes.plan_traits.max_scanned_pages,
            mobile_guidelines: attributes.plan_traits.mobile_guidelines,
            monsido_score: attributes.plan_traits.monsido_score,
            monsido_starter_branding: attributes.plan_traits.monsido_starter_branding,
            nag_for_upgrade: attributes.plan_traits.nag_for_upgrade,
            on_demand_scans: attributes.plan_traits.on_demand_scans,
            on_demand_scans_per_month: attributes.plan_traits.on_demand_scans_per_month,
            page_assist: attributes.plan_traits.page_assist,
            page_details: attributes.plan_traits.page_details,
            page_fix: attributes.plan_traits.page_fix,
            paying: attributes.plan_traits.paying,
            performance_tracking: attributes.plan_traits.performance_tracking,
            performance_tracking_allowed_frequencies: attributes.plan_traits.performance_tracking_allowed_frequencies?.length
                ? [...attributes.plan_traits.performance_tracking_allowed_frequencies]
                : attributes.plan_traits.performance_tracking_allowed_frequencies,
            performance_tracking_max_page_profiles: attributes.plan_traits.performance_tracking_max_page_profiles,
            performance_tracking_max_pages: attributes.plan_traits.performance_tracking_max_pages,
            performance_tracking_max_profiles: attributes.plan_traits.performance_tracking_max_profiles,
            performance_tracking_on_demand_scan: attributes.plan_traits.performance_tracking_on_demand_scan,
            policies_all: attributes.plan_traits.policies_all,
            policy_bot: attributes.plan_traits.policy_bot,
            prioritized_content_all: attributes.plan_traits.prioritized_content_all,
            qa_links: attributes.plan_traits.qa_links,
            qa_spellcheck: attributes.plan_traits.qa_spellcheck,
            qa_summary: attributes.plan_traits.qa_summary,
            quick_search: attributes.plan_traits.quick_search,
            readability: attributes.plan_traits.readability,
            report_center_all: attributes.plan_traits.report_center_all,
            scan_interval: attributes.plan_traits.scan_interval,
            script_setup_guide: attributes.plan_traits.script_setup_guide,
            seo_all: attributes.plan_traits.seo_all,
            single_page_scan_any_url: attributes.plan_traits.single_page_scan_any_url,
            skip_on_hold: attributes.plan_traits.skip_on_hold,
            source_code_excludes: attributes.plan_traits.source_code_excludes,
            spell_check_engine_priority: attributes.plan_traits.spell_check_engine_priority?.length
                ? [...attributes.plan_traits.spell_check_engine_priority]
                : attributes.plan_traits.spell_check_engine_priority,
            statistics: attributes.plan_traits.statistics,
            uptime_intervals: attributes.plan_traits.uptime_intervals?.length
                ? [...attributes.plan_traits.uptime_intervals]
                : attributes.plan_traits.uptime_intervals,
        };
        this.plan_type = attributes.plan_type;
        this.reseller = {
            id: attributes.reseller.id,
            name: attributes.reseller.name,
        };
        this.html_pages_count = attributes.html_pages_count;
        this.documents_count = attributes.documents_count;
        this.vertical = attributes.vertical;
    }
}
