import { Injectable } from '@angular/core';
import { AccessibilitySummaryEntityType } from '@client/app/modules/accessibility/interfaces/accessibility-summary.interface';

@Injectable({
    providedIn: 'root',
})
export class ComplianceAccessibilityLegacyService {

    getDataFromResultType (resultType: string, summary: AccessibilitySummaryEntityType): [number, number] {
        if (!this.isObject(summary)) {
            return [0, 0];
        }
        switch (resultType) {
            case 'errors':
                return this.getDataWithResultTypeError(summary);
            case 'errors_and_warnings':
                return this.getDataWithResultTypeErrorsAndWarnings(summary);
            default:
                return this.getDataWithNoResultType(summary);
        }
    }

    getPercentageFromResultType (resultType: string, summary: AccessibilitySummaryEntityType): number {
        let errors = 0;

        if (!this.isObject(summary)) {
            return 0;
        }

        switch (resultType) {
            case 'errors':
                errors = summary.pages_by_result_type.error || 0;
                break;
            case 'errors_and_warnings':
                errors = summary.pages_by_result_type.error_and_warning || 0;
                break;
            default:
                errors = summary.pages_with_issues || 0;
        }

        return (errors / summary.pages) * 100;
    }

    private getDataWithResultTypeError (summary: AccessibilitySummaryEntityType): [number, number] {
        let errors = 0;

        if (this.isObject(summary.pages_by_result_type)) {
            errors = summary.pages_by_result_type.error || 0;
        }

        return [summary.pages - errors, errors];
    }

    private getDataWithNoResultType (summary: AccessibilitySummaryEntityType): [number, number] {
        const errors = summary.pages_with_issues || 0;
        return [summary.pages - errors, errors];
    }

    private getDataWithResultTypeErrorsAndWarnings (summary: AccessibilitySummaryEntityType): [number, number] {
        let errors = 0;

        if (this.isObject(summary.pages_by_result_type)) {
            errors = summary.pages_by_result_type.error_and_warning || 0;
        }

        return [summary.pages - errors, errors];
    }

    private isObject (obj: unknown): boolean {
        return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
    }
}
