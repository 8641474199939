import { Injectable } from '@angular/core';
import { PagePerformanceAuditScoreUtil } from '../../../services/page-performance-audit-score.util';
import MONSIDO_COLOR from '@monsido/colors';
@Injectable({
    providedIn: 'root',
})
export class PagePerformanceChartsService {
    schema = 'dark';
    colors = {
        light: ['#fde3e3', '#fce0c3', '#65cccf'],
        dark: [MONSIDO_COLOR['secondary-7'], MONSIDO_COLOR['secondary-15'], MONSIDO_COLOR['secondary-16']],
    };

    constructor () { }

    getColorByScore (score: number, schema: string): string {
        const val = PagePerformanceAuditScoreUtil.getProperScoreNo(score);
        if (PagePerformanceAuditScoreUtil.hasScoreFailed(val)) {
            return this.getFailedColor(schema);
        } else if (PagePerformanceAuditScoreUtil.hasScoreAverage(val)) {
            return this.getAverageColor(schema);
        } else if (PagePerformanceAuditScoreUtil.hasScorePassed(val)) {
            return this.getPassedColor(schema);
        }
        return '';
    }

    setColorSchema (schema?: string): void {
        this.schema = schema || 'dark';
    }

    getFailedColor (schema?: string): string {
        return schema ? this.colors[schema][0] : this.colors[this.schema][0];
    }

    getAverageColor (schema?: string): string {
        return schema ? this.colors[schema][1] : this.colors[this.schema][1];
    }

    getPassedColor (schema?: string): string {
        return schema ? this.colors[schema][2] : this.colors[this.schema][2];
    }
}
