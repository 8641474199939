(function () {
    'use strict';

    angular.module('app.forms').component('formDomainFeatures', {
        templateUrl: 'app/forms/domain/steps/features/features.html',
        controller: DomainFeaturesController,
        controllerAs: 'vm',
        bindings: {
            domain: '=',
            form: '<?',
        },
    });

    DomainFeaturesController.$inject = [
        'ng2SessionService',
        'gettextCatalog',
        '$filter',
        '$q',
        'AccessibilitySourceCodeExcludeRepo',
        'SourceCodeExcludesApiService',
        'SCE_MODULES',
        'ng2ActiveFeatureService',
        'MON_EVENTS',
        'ng2MonEventsService',
        'ng2DefaultConstraintsAndExcludesService',
        'Lodash',
        'ng2GuidelineInfoService',
    ];
    /* @ngInject */
    function DomainFeaturesController (
        ng2SessionService,
        gettextCatalog,
        $filter,
        $q,
        AccessibilitySourceCodeExcludeRepo,
        SourceCodeExcludesApiService,
        SCE_MODULES,
        ng2ActiveFeatureService,
        MON_EVENTS,
        ng2MonEventsService,
        ng2DefaultConstraintsAndExcludesService,
        Lodash,
        ng2GuidelineInfoService,
    ) {
        const vm = this;
        vm.featureAccessibilityGuidelineId = '';
        vm.isWcag = false;
        vm.$onInit = activate;
        vm.getSourceCodeExcludes = getSourceCodeExcludes;
        vm.onSCEChange = onSCEChange;
        vm.openDataPrivacy = openDataPrivacy;
        vm.openPageCorrectSettings = openPageCorrectSettings;
        vm.unlinkAdvancedAnalyticsUrl = unlinkAdvancedAnalyticsUrl;

        const guidelineMapEntries = Object.entries({
            ...ng2GuidelineInfoService.wcag,
            ...ng2GuidelineInfoService.qw,
        });

        vm.onGuidelineChange = () => {
            const version = !!ng2GuidelineInfoService.wcag[vm.featureAccessibilityGuidelineId]
                ? ng2GuidelineInfoService.currentWCAGGuidelineVersion
                : ng2GuidelineInfoService.currentQWGuidelineVersion;
            if (vm.featureAccessibilityGuidelineId) {
                vm.domain.features.accessibility = `${vm.featureAccessibilityGuidelineId}_v${version}`;
            } else {
                vm.domain.features.accessibility = undefined;
            }
            vm.isWcag = isWcag();
        };

        function isWcag () {
            const isGuidelineWcag = !!ng2GuidelineInfoService.wcag[vm.featureAccessibilityGuidelineId];
            if (!isGuidelineWcag) {
                vm.domain.features.accessibility_errors_only = false;
            }
            return isGuidelineWcag;
        }

        function activate () {
            vm.sourceCodeExcludes = [];
            vm.promiseSourceCodeExcludes = null;
            vm.domainExcludes = [];
            vm.customer = ng2SessionService.customer;

            if (typeof vm.domain.features.statistics !== 'boolean') {
                vm.domain.features.statistics = vm.customer.settings.statistics;
            }

            vm.domain.features.accessibility = vm.domain.features.accessibility || vm.customer.settings.accessibility;
            vm.featureAccessibilityGuidelineId = (vm.domain.features.accessibility || '').split('_v')[0];

            vm.showAutofix = false;
            vm.showHeatmaps = false;
            vm.loadingProfiles = false;
            vm.showDataPrivacy = false;

            setupAccessibilityOptions(vm.domain.features.accessibility);
            setupReadabilityOptions();
            setupPlanSpecificFeature();
            setupClairtyOptions();
            ng2DefaultConstraintsAndExcludesService.defaultCms$.subscribe((cms) => {
                if (!cms) {
                    return;
                }
                vm.domainExcludes = Lodash.unionBy(vm.domainExcludes, vm.sourceCodeExcludes.filter((sce) => sce.default_for_cms === cms), 'id');
            });
            vm.isWcag = isWcag();
        }

        function openDataPrivacy () {
            var params = {
                body: 'domainDataPrivacySettings',
                size: 'md',
                data: {
                    domain: vm.domain,
                },
            };

            const options = {
                params,
                callback: (settings) => {
                    if (settings) {
                        vm.domain.settings.data_protection = settings;
                    }
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function openPageCorrectSettings () {
            var params = {
                body: 'pageFixFormDomainSettings',
                size: 'md',
                data: {
                    domain: vm.domain,
                    saveToAPI: false,
                },
            };

            const options = {
                params,
                callback: (settings) => {
                    if (settings) {
                        vm.domain.settings.scripts.page_correct.admin_only = settings.admin_only;
                        vm.domain.settings.scripts.page_correct.enabled_checks = settings.enabled_checks;
                        if (vm.form) {
                            vm.form.$setDirty();
                        }
                    }
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options);
        }

        function getSourceCodeExcludes () {
            if (vm.promiseSourceCodeExcludes !== null) {
                return vm.promiseSourceCodeExcludes;
            }
            var promises = [];

            if (ng2ActiveFeatureService.isFeatureActive('accessibility', vm.domain)) {
                promises.push(getAccessibilitySCE());
            }

            if (
                ng2ActiveFeatureService.isFeatureActive('admin_source_code_excludes', vm.domain) &&
                ng2ActiveFeatureService.isFeatureActive('readability', vm.domain)
            ) {
                promises.push(getReadabilitySCE());
            }

            if (ng2ActiveFeatureService.isFeatureActive('admin_source_code_excludes', vm.domain)) {
                promises.push(getMisspellingSCE());
            }

            vm.promiseSourceCodeExcludes = $q.all(promises).then(function (values) {
                values.forEach(function (sceCollection) {
                    if (sceCollection) {
                        vm.sourceCodeExcludes = vm.sourceCodeExcludes.concat(sceCollection);
                    }
                });
                vm.domainExcludes = vm.sourceCodeExcludes.filter(function (sce) {
                    if (sce.module === SCE_MODULES.accessibility) {
                        return vm.domain.accessibility_source_code_excludes.indexOf(sce.id) > -1;
                    } else {
                        return vm.domain.source_code_excludes.indexOf(sce.id) > -1;
                    }
                });
                // Make sure that the domain object stays updated with the sce data
                onSCEChange([], vm.domainExcludes);
                return vm.sourceCodeExcludes;
            });
            return vm.promiseSourceCodeExcludes;
        }

        function onSCEChange (oldVal, newSCEs) {
            vm.domain.accessibility_source_code_excludes = [];
            vm.domain.source_code_excludes = [];

            newSCEs.forEach(function (sce) {
                if (sce.module === SCE_MODULES.accessibility) {
                    vm.domain.accessibility_source_code_excludes.push(sce.id);
                } else {
                    vm.domain.source_code_excludes.push(sce.id);
                }
            });
        }

        function unlinkAdvancedAnalyticsUrl () {
            vm.domain.features.advanced_analytics_url = null;
            if (vm.form) {
                vm.form.$setDirty();
            }
        }

        // PROTECTED

        function setupClairtyOptions () {
            vm.clarityOptions = [
                {
                    guideline_id: 'WCAG2_0',
                    name: gettextCatalog.getString('WCAG 2.0'),
                },
                {
                    guideline_id: 'WCAG2_1',
                    name: gettextCatalog.getString('WCAG 2.1'),
                },
            ];
        }

        function setupAccessibilityOptions (accessibilityGuideline) {
            const isMobileGuideline = vm.customer?.plan_traits?.mobile_guidelines;

            const isOldGuideline = (typeof accessibilityGuideline === 'string' &&
                (accessibilityGuideline.includes('v2') || !accessibilityGuideline.includes('_v')));

            vm.accessibilities = createGuidelineOpts(isOldGuideline, isMobileGuideline);
        }

        function setupReadabilityOptions () {
            vm.readabilities = [
                {
                    readability_test_id: 'flesch_kincaid_re',
                    name: $filter('translateReadability')('flesch_kincaid_re') + ' ' + gettextCatalog.getString('Readability test (for English pages only)'),
                },
                {
                    readability_test_id: 'lix',
                    name: $filter('translateReadability')('lix') + ' ' + gettextCatalog.getString('Readability test'),
                },
            ];
        }

        function setupPlanSpecificFeature () {
            const customer = ng2SessionService.customer;
            if (customer) {
                if (customer.plan_traits.page_fix) {
                    vm.showAutofix = $filter('activeFeature')('page_fix');
                }
                if (customer.plan_traits.data_protection) {
                    vm.showDataPrivacy = $filter('activeFeature')('data_privacy');
                }
            }
        }

        function getAccessibilitySCE () {
            const params = {
                page_size: 0,
                include_inherited: true,
            };

            return AccessibilitySourceCodeExcludeRepo.getAll(params).then(
                function (excludes) {
                    return excludes.map(function (exclude) {
                        exclude.module = SCE_MODULES.accessibility;
                        return exclude;
                    });
                },
                function () {
                    return [];
                },
            );
        }

        function getReadabilitySCE () {
            const params = {
                page_size: 0,
            };

            return SourceCodeExcludesApiService.getAll(SCE_MODULES.readability, params).then(
                function (sces) {
                    return sces.map(function (sce) {
                        sce.module = SCE_MODULES.readability;
                        return sce;
                    });
                },
                function () {
                    return [];
                },
            );
        }

        function getMisspellingSCE () {
            const params = {
                page_size: 0,
            };

            return SourceCodeExcludesApiService.getAll(SCE_MODULES.misspelling, params).then(
                function (sces) {
                    return sces.map(function (sce) {
                        sce.module = SCE_MODULES.misspelling;
                        return sce;
                    });
                },
                function () {
                    return [];
                },
            );
        }

        function createGuidelineOpts (isOldGuideline, isMobileGuideline) {
            const res = [];

            for (const [guideline, info] of guidelineMapEntries) {
                const isWCAG22Mobile = guideline.includes('WCAG22') && guideline.includes('mobile');

                if (guideline.includes('mobile') && !isMobileGuideline) {
                    continue;
                }
                if (isOldGuideline && isWCAG22Mobile) {
                    continue;
                }
                res.push(createGuidelineOption(guideline, info.name));
            }
            return res;
        }

        function createGuidelineOption (id, name) {
            return {
                accessibility_guideline_id: id,
                name: name,
            };
        }
    }
})();
